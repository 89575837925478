import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Header from '../components/Header';
import MetaFooter from '../components/MetaFooter';
import Heading from '../components/Heading';
import Footer from '../components/Footer';
import GoogleMap from '../components/GoogleMap';
import SideLogo from '../components/SideLogo';
import { cssVariables } from '../config';
import camper2 from './../images/camper2.svg';
import PageHead from '../components/PageHead';

const TextWrapper = styled.div`
  padding: 80px 25px 60px 25px;
  margin: 0 auto;
  max-width: 980px;
  p,
  h3,
  a,
  ul {
    font-size: 18px;
    line-height: 26px;
    color: #4a4a4a;
  }
  li {
    margin: 0.5em 0;
  }
  a {
    text-decoration: underline;
    @media (max-width: ${cssVariables.breakPoints.tablet}) {
      word-break: break-all;
    }
    &:hover {
      color: ${cssVariables.color.green1};
    }
  }

  h3 {
    font-family: fira-sans-medium, sans-serif;
    margin: 2em 0 0.5em 0;
  }
  p {
    margin: 0 0 1em 0;
  }
  ul {
    padding-left: 1em;
  }
`;

export default () => (
  <Layout>
    <Header toIndex />
    <PageHead />
    <TextWrapper>
      <Heading style={{ marginTop: '80px' }} title="Datenschutzerklärung" />
      <p style={{ marginTop: '70px' }}>
        Herzlich willkommen auf unseren Internetseiten. Schutz Ihrer
        Privatsphäre und Transparenz unserer Datenverarbeitung sind uns sehr
        wichtig. Daher nehmen Sie bitte nachstehende Informationen zur Kenntnis:
      </p>
      <h3>1. Zentrale Begrifflichkeiten</h3>
      <p>
        Zunächst möchten wir Sie mit einigen zentralen Begriffen des
        Datenschutzrechts vertraut machen. Die nachfolgenden Definitionen sind
        Art. 4 der europäischen Datenschutzgrundverordnung (DS-GVO) entnommen:
      </p>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        "betroffene Person") beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
        besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
        genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
        Identität dieser natürlichen Person sind, identifiziert werden kann.
      </p>
      <p>
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
        das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
        Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
        Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den
        Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
        Vernichtung.
      </p>
      <p>
        „Einschränkung der Verarbeitung“ ist die Markierung gespeicherter
        personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
        einzuschränken.
      </p>
      <p>
        "Profiling" ist jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese personenbezogenen
        Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
        eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte
        bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche
        Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
        Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
      </p>
      <p>
        „Verantwortlicher“ ist die natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das
        Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der
        Verantwortliche beziehungsweise können die bestimmten Kriterien seiner
        Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
        vorgesehen werden.
      </p>
      <p>
        „Einwilligung“ der betroffenen Person ist jede freiwillig für den
        bestimmten Fall, in informierter Weise und unmissverständlich abgegebene
        Willensbekundung in Form einer Erklärung oder einer sonstigen
        eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
        verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
        personenbezogenen Daten einverstanden ist. Den vollständigen
        Gesetzestext können Sie hier einsehen:
      </p>
      <a
        rel="noopener noreferrer"
        href="http://www.eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE"
        target="_blank"
      >
        eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE
      </a>
      <h3>
        2. Für die Datenverarbeitung Verantwortlicher, Datenschutzbeauftragter
      </h3>
      <p>Verantwortlicher im Sinne des Art. 4 DS-GVO ist:</p>
      <p>Sandra Stimberg</p>
      <p>Telefon: +49(0) 2363 32371</p>
      <p>Recklinghäuser Str. 140</p>
      <p>E-Mail: info@stimberg-camping.de</p>
      <p>45711 Datteln</p>
      <h3>3. Zweck der Datenverarbeitung und Rechtsgrundlage</h3>
      <ul>
        <li>
          Beantwortung Ihrer Anfrage – Rechtsagrundlage ist Art. 6 Abs. 1 S. 1
          lit. b DS-GVO
        </li>
        <li>
          Identifikation Ihrer Person als unseren Kunden - Rechtsgrundlage ist
          Art. 6 Abs. 1 S. 1 lit. b DS-GVO
        </li>
        <li>
          Führung der Korrespondenz mit Ihnen - Rechtsgrundlage ist Art. 6 Abs.
          1 S. 1 lit. b DS-GVO
        </li>
      </ul>
      <h3>4. Weitergabe von Daten an Dritte</h3>
      <p>
        Unsererseits erfolgt nur eine Weitergabe Ihrer personenbezogenen Daten
        an Dritte wenn und soweit wir hierzu gesetztlich verpflichtet sind.
      </p>
      <h3>
        5. Erhebung personenbezogener Daten bei Besuch unseres Internetauftritts
      </h3>
      <p>
        Bei der bloß informatorischen Nutzung der Webseite, also wenn Sie sich
        nicht registrieren oder uns anderweitig Informationen übermitteln,
        erheben wir nur solche personenbezogenen Daten, die Ihr Browser an
        unsere Server übermittelt. Wenn Sie unsere Webseiten betrachten möchten,
        erheben wir die folgenden Daten, die für uns technisch erforderlich
        sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität und
        Sicherheit unseres Internetauftrittes zu gewährleisten
        <br />
        (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
      </p>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Webseite, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
      <p>
        Zusätzlich zu den vorgenannten Daten werden bei Ihrer Nutzung dieser
        Webseite Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es
        sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
        verwendeten Browser zugeordnet gespeichert werden und durch welche der
        Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen
        zufließen. Cookies dienen dazu, das Internetangebot insgesamt
        nutzerfreundlicher und effektiver zu machen. Dieser Internetauftritt
        nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im
        Folgenden erläutert werden: Transiente Cookies werden automatisiert
        gelöscht, wenn Sie den Browser schließen. Hierzu zählen insbesondere
        Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher
        sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
        zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie
        auf unsere Webseite zurückkehren. Die Session-Cookies werden gelöscht,
        wenn Sie sich ausloggen oder den Browser schließen. Persistente Cookies
        werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je
        nach Cookie unterscheiden kann. Sie können die Cookies in den
        Sicherheitseinstellungen Ihres Browsers jederzeit löschen. Falls Sie
        über einen Account dieses Internetauftritts verfügen, setzen wir Cookies
        ein, um Sie für Folgebesuche identifizieren zu können. Andernfalls
        müssten Sie sich für jeden Besuch erneut einloggen. Flash-Cookies werden
        nicht durch Ihren Browser erfasst, sondern durch Ihr Flash-Plug-in.
        Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät
        abgelegt werden. Diese Objekte speichern die erforderlichen Daten
        unabhängig von Ihrem verwendeten Browser und haben kein automatisches
        Ablaufdatum. Sie können Ihre Browser-Einstellung entsprechend Ihren
        Wünschen konfigurieren und z.B. die Annahme von Third-Party-Cookies oder
        allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie bei
        entsprechender Konfiguration eventuell nicht alle Funktionen dieser
        Webseite nutzen können. Wenn Sie keine Verarbeitung der Flash-Cookies
        wünschen, müssen Sie ein entsprechendes Add-On installieren, z.B.
        „Better Privacy" für Mozilla firefox (
        <a
          rel="noopener noreferrer"
          href="https://addons.mozilla.org/de/firefox/addon/betterprivacy/"
          target="_blank"
        >
          https://addons.mozilla.org/de/firefox/addon/betterprivacy/
        </a>
        ) oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von
        HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser
        den privaten Modus einsetzen. Zudem empfehlen wir, regelmäßig Ihre
        Cookies und den Browser-Verlauf manuell zu löschen.
      </p>
      <h3>6. Weitere Funktionen und Angebote dieser Webseite</h3>
      <p>
        Neben der rein informatorischen Nutzung unserer Webseite bieten wir
        verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu
        müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir
        zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor
        genannten Grundsätze zur Datenverarbeitung gelten.
      </p>
      <h3>6.1. Anfragen per E-Mail</h3>
      <p>
        Sie können mit uns Kontakt über die auf der Internetseite angegebene
        E-Mailadresse aufnehmen. In diesem Fall werden Ihre Emailadresse, der
        Text der von Ihnen verfassten Email und eventuelle Dateianhänge, die Sie
        Ihrer Email beigefügt haben, gespeichert. Zudem werden in beiden Fällen
        der vorbeschriebenen Kontaktaufnahme ergänzend Ihre IP-Adresse sowie
        Datum und Uhrzeit der Absendung Ihrer Anfrage, beziehungsweise Ihrer
        Email gespeichert. Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 S. 1
        lit. f DS-GVO. Unser berechtigtes Interesse liegt in der Führung einer
        Kommunikation mit Ihnen als Interessent unserer Leistungen per Email.
        Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die
        Speicherung nicht mehr erforderlich oder schränken die Verarbeitung ein,
        falls gesetzliche Aufbewahrungspflichten bestehen.
      </p>
      <h3>6.2. Google Maps</h3>
      <p>
        Auf dieser Webseite nutzen wir das Angebot von Google Maps, einen
        Kartendienst der Google Inc. („Google“). Dadurch können wir Ihnen
        interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen
        die komfortable Nutzung der Karten-Funktion. Durch den Besuch auf der
        Webseite erhält Google die Information, dass Sie die entsprechende
        Unterseite unserer Webseite aufgerufen haben. Zudem werden die unter
        Ziffer 2 dieser Erklärung genannten Daten übermittelt. Dies erfolgt
        unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie
        eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google
        eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn
        Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie
        sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten
        als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
        und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche
        Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer)
        zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
        sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu
        informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
        dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google
        richten müssen. Weitere Informationen zu Zweck und Umfang der
        Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten
        Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch
        weitere Informationen zu Ihren diesbezüglichen Rechten und
        Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.google.de/intl/de/policies/privacy"
        >
          http://www.google.de/intl/de/policies/privacy
        </a>
        . Google verarbeitet Ihre personenbezogenen Daten auch in den USA und
        hat sich dem EU-US Privacy Shield unterworfen,
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.privacyshield.gov"
        >
          {' '}
          www.privacyshield.gov
        </a>
        .
      </p>
      <h3>7. Datenlöschung und Speicherdauer</h3>
      <p>
        Sofern wir vorstehend keine speziellen Ausführungen zu Datenlöschung und
        Speicherdauer getätigt haben, weisen wir allgemein darauf hin, dass über
        diese Webseite erhobene personenbezogene Daten gelöscht oder gesperrt
        werden, sobald der Zweck der Speicherung entfällt. Eine fortgesetzte
        Speicherung findet jedoch statt, wenn dies gesetzlich vorgeschrieben
        ist. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine
        gesetzlich vorgegebene Speicherfrist abläuft, es sei denn, dass eine
        fortgesetzte Speicherung für einen Vertragsabschluss oder eine
        Vertragserfüllung erforderlich ist.
      </p>
      <h3>8. Ihre Rechte</h3>
      <p>
        Sie haben gegenüber uns hinsichtlich der Sie betreffenden
        personenbezogenen Daten das Recht auf Auskunft (Art 15 DS-GVO), der
        Berichtigung (Art. 16 DS-GVO), der Löschung (Art. 17 DS-GVO), der
        Einschränkung der Verarbeitung (Art. 18 DS-GVO) und auf
        Datenübertragbarkeit (Art. 20 DS-GVO).
      </p>
      <p>Widerspruchsrecht nach Art. 21 Abs. 1 und 2 DS-GVO:</p>
      <p>
        Sie haben das Recht einer Verarbeitung Ihrer personenbezogenen Daten zu
        Zwecken der Direktwerbung und/oder des Profilings zu widersprechen.
      </p>
      <p>
        Sofern wir Ihre personenbezogenen Daten zum Zweck der Wahrung
        berechtigter Interessen verarbeiteten oder Profiling betreiben, können
        Sie dieser Verarbeitung widersprechen, wenn sich aus Ihrer besonderen
        Situation hierfür Gründe ergeben.
      </p>
      <p>
        Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben,
        können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst
        die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem
        Sie ihn gegenüber uns ausgesprochen haben.
      </p>
      <p>
        Zudem haben Sie das Recht sich bei einer Datenschutzaufsichtsbehörde zu
        beschweren. Die für uns zuständige Behörde ist der Landesbeauftragte für
        Datenschutz und Informationsfreiheit Nordrhein-Westfalen, Kavalleriestr.
        2-4, 40213 Düsseldorf.
      </p>
    </TextWrapper>
    <Footer />
    <SideLogo small>
      <img src={camper2} alt="" />
    </SideLogo>
    <GoogleMap />
    <MetaFooter />
  </Layout>
);
